
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* src/ComingSoon.css */
@keyframes fadeInOut {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.coming-soon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  background-color: #f3f3f3;
  text-align: center;
}

.coming-soon-content {
  background: white;
  padding: 20px 40px;
  border-radius: 10px;
  box-shadow: 4px 4px 8px #CBE1D5;
}

h1 {
  margin-bottom: 10px;
  font-size: 2.5em;
  color: #333;
}

p {
  font-size: 1.2em;
  color: #666;
}

.countdown {
  font-size: 1.5em;
  color: #107ACA;
  animation: fadeInOut 1s ease-in-out infinite;
}

