@tailwind base;
@tailwind components;
@tailwind utilities;


.image-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Adjust the gap as needed */
  }
  
  .uploaded-image {
    max-width: calc(50% - 10px); /* Adjust the width and gap as needed */
    margin-bottom: 10px; /* Adjust the vertical gap as needed */
  }
  /* Custom CSS in a separate stylesheet */

@media screen and (max-width: 780px) {
  .xs-bg-black {
    background-color: #343A40;
    border-radius: 5px;
    opacity:0.6 ;
  }
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Adjust height as needed */
  font-size: 18px;
  font-weight: bold;
}

/* Optional: Add animation for a spinning loader */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader::after {
  content: "";
  display: inline-block;
  width: 24px; /* Adjust width as needed */
  height: 24px; /* Adjust height as needed */
  border: 4px solid #ccc;
  border-top-color: #333;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
